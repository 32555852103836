<template>
  <div id="missing-data-slide-component">
    <template v-if="showFiling">
      <slide-heading :heading="slide.heading" />

      <item-requiring-attention
        v-if="schema?.length && hasVisibleFields"
        :schema-loaded="true"
        @no-visible-fields="hasVisibleFields = false"
      />
      <div v-else>
        <p class="text-center">
          Looks like there is no additional data that is needed
        </p>

        <div class="buttons-div">
          <b-button
            class="button-back"
            variant="default"
            aria-label="back button"
            @click="$emit('previous-slide')"
          >
            <fa-icon icon="chevron-left" class="fa-xs" /> Back
          </b-button>
          <b-button
            class="next-button"
            variant="primary"
            aria-label="continue button"
            @click="$emit('next-slide')"
          >
            <span class="px-3">
              Continue
            </span>
          </b-button>
        </div>
      </div>
    </template>

    <div v-else class="loading-slide">
      <slide-heading
        heading="Let’s review and see if we'll need any other information..."
      />

      <p>
        Reviewing data... checking inefficient agency bureaucracy...
      </p>
      <ct-fake-loading-bar
        class="loading-bar"
        :actually-complete="loaded"
        @progress-bar-complete="showFiling = true"
      />

      <div class="buttons-div">
        <b-button
          v-if="loaded"
          class="button-back"
          variant="default"
          aria-label="back button"
          @click="$emit('previous-slide')"
        >
          <fa-icon icon="chevron-left" class="fa-xs" /> Back
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'MissingDataSlide',
  components: {
    CtFakeLoadingBar:       () => import('@/components/shared/CtFakeLoadingBar'),
    SlideHeading:           () => import('@/components/StagelineV2/shared/SlideHeading'),
    ItemRequiringAttention: () => import('@/components/StagelineV2/schemaForm/online/ItemRequiringAttention'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: false,
      showFiling: false,
      hasVisibleFields: true,
    }
  },
  computed: {
    ...mapGetters('checkout', ['bundleInCartWithFilingProduct', 'cartItemByCategory']),
    ...mapGetters('schema', ['schema']),
    ...mapGetters('jurisdictions', ['findByAbbreviation']),
    ...mapGetters('stageline', ['company']),
    ...mapGetters('stagelineSchemaForm', ['formattedProcessedFormData']),
  },
  async mounted() {
    this.loaded = false
    this.resetState()
    const slideTargetProductInCart = this.cartItemByCategory(this.slide.layout_sub_type) ||
      this.bundleInCartWithFilingProduct(this.slide.layout_sub_type)

    const filingProductInCart = slideTargetProductInCart?.product_type === 'ProductBundle' ?
      Object.values(slideTargetProductInCart.data).find(value => value.filing_name === this.slide.layout_sub_type) :
      slideTargetProductInCart

    const filingMethodId = filingProductInCart?.filing_method_id

    if (filingMethodId) {
      await this.fetchProcessedFormData()
      await this.createCartItemObject(slideTargetProductInCart, filingProductInCart)
      await this.loadFilingProductSchemaByFilingMethod({ filingMethodId })
      if (this.formattedProcessedFormData) await this.filterOutFieldsAlreadyAnsweredInPdf()
      await this.setObjectIdAndField({ objectId: slideTargetProductInCart.id, startingField: 'first' })
    }
    this.loaded = true
  },
  methods: {
    ...mapActions('schema', [
      'loadFilingProductSchemaByFilingMethod',
      'setSchema',
      'setObject',
      'resetState',
    ]),
    ...mapActions('stageline', [
      'setObjectIdAndField',
    ]),
    ...mapActions('stagelineSchemaForm', [
      'fetchProcessedFormData',
    ]),

    async filterOutFieldsAlreadyAnsweredInPdf() {
      const pdfData = this.formattedProcessedFormData?.default || []

      if (pdfData) {
        this.schema.forEach(schemaField => {
          if (schemaField.name in pdfData) schemaField.type = 'hidden'
        })
      }

      await this.setSchema(this.schema)
    },

    async createCartItemObject(slideTargetCartItem, filingProductInCart) {
      let cartItemObject = {}
      cartItemObject['data'] = filingProductInCart.data
      cartItemObject['jurisdiction'] = this.findByAbbreviation(slideTargetCartItem?.display_info?.jurisdiction_abbreviation) || filingProductInCart?.filing_method?.jurisdiction
      cartItemObject['name'] = slideTargetCartItem.title
      cartItemObject['type'] = 'shopping-cart-filing'
      cartItemObject['id'] = slideTargetCartItem.id
      cartItemObject['company'] = this.company
      cartItemObject['product'] = {
        id: filingProductInCart.product_id,
        filing_name: filingProductInCart.filing_name,
        filing_method: filingProductInCart.filing_method,
        kind: filingProductInCart.kind,
      }

      await this.setObject({ object: cartItemObject })
    },
  },
}
</script>

<style lang="scss" scoped>

.loading-slide {
  text-align: center;

  h3 {
    margin-bottom: 2em;
  }

  .loading-bar {
    margin: 0 35% 2em;

    ::v-deep .progress-bar {
      background-color: #7D64D8;
    }
  }
}

.buttons-div {
  float: right;

  .button-back {
    color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-light;
    }
  }

  .button-next {
    background: $ct-ui-primary;
    border-color: $ct-ui-primary;

    &:hover {
      background: $ct-ui-primary-dark;
      border-color: $ct-ui-primary;
    }
  }
}

.next-button {
  width: auto;
  min-width: 8.125em;
  padding: 0.6rem 0;
  background: $ct-ui-primary;
  border-color: $ct-ui-primary;

  &:hover {
    background: $ct-ui-primary-dark;
    border-color: $ct-ui-primary;
  }
}
</style>
